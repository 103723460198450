import React from 'react';
import { styled } from '@mui/material/styles';
import Search from '@bamboo/core-lib/components/Icons/Search';
import { IconButton } from '@mui/material';

const Root = styled('section')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  borderRadius: '40px',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  boxShadow: 'rgba(0, 0, 0, 0.19) -18px 23px 48px 0px',
  [theme.breakpoints.down('md')]: {
    padding: '4px 0 4px 0',
  },
}));

const VideoContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  top: '0px',
  left: '0px',
  position: 'absolute',
  zIndex: 0,
  direction: 'ltr',
  transition: 'opacity 1s ease 0s',
  pointerEvents: 'none',
  borderRadius: '40px',
  [theme.breakpoints.down('md')]: {
    borderRadius: '0',
  },
}));

const VideoStyled = styled('video')(() => ({
  margin: '0px',
  border: 'medium none',
  maxWidth: '100%',
  lineHeight: 1,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
}));

const DisplayContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  marginRight: 'auto',
  marginLeft: 'auto',
  position: 'relative',
  height: '55vh',
  maxHeight: '600px',
  [theme.breakpoints.down('lg')]: {
    height: '60vh',
  },
}));

const Container = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  padding: '0%',
  alignContent: 'center',
  alignItems: 'center',
}));

const WelcomeContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  marginBottom: '10px',
  color: 'rgb(255, 255, 255)',
  fontSize: '9px',
  fontWeight: 400,
  textTransform: 'uppercase',
  marginTop: '10px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const TitleContainer = styled('div')(({ theme }) => ({
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  margin: '0% 0% 0% 10%',
  [theme.breakpoints.down('md')]: {
    bottom: '20%',
    position: 'absolute',
    margin: '0% 0% 0% 30px',
  },
}));

const Title = styled('h2')(({ theme }) => ({
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  color: 'rgb(255, 255, 255)',
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: '1em',
  letterSpacing: '-1.4px',
  whiteSpace: 'break-spaces',
  [theme.breakpoints.down('md')]: {
    fontSize: '30px',
    fontWeight: 400,
  },
}));

const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  marginBottom: '10px',
  padding: '1% 0% 0% 10%',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    bottom: '-25px',
    margin: '0px',
    display: 'flex',
    padding: '0',
    justifyContent: 'center',
  },
}));

const Form = styled('form')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

const InputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '46%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const InputStyled = styled('input')(({ theme }) => ({
  margin: '0px',
  lineHeight: 1.5,
  overflow: 'visible',
  appearance: 'textfield',
  outlineOffset: '-2px',
  boxSizing: 'border-box',
  transition: 'all 0.3s ease 0s',
  backgroundPosition: '97% center',
  border: '0px solid gainsboro',
  borderRadius: '100px',
  marginTop: '7px',
  width: '100%',
  height: 60,
  fontSize: '16px',
  fontWeight: 500,
  fontFamily: theme.typography.fontFamily,
  padding: '12px 12px 12px 22px',
  '&:focus': {
    borderColor: '#333',
    outline: '0 none',
  },
}));

const SearchButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: '5px',
  bottom: '0px',
  top: '7px',
  transition: 'all 0.3s ease 0s',
  color: '#7f7f7f',
}));

const SampleContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  marginBottom: '10px',
  color: 'rgb(255, 255, 255)',
  fontSize: '11px',
  fontWeight: 400,
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  margin: '0% 40% 0% 10%',
  padding: '0px 0px 0px 17px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const DefaultLabels = {
  title: `Finalmente um\nbanco de imagens\nverdadeiramente\nBrasileiro`,
  welcome: 'Bem vindo à bamboo stock : )',
  inputPlaceholder: 'Pesquisar...',
  searchSample: 'Experimente pesquisar: “Academia” “Férias” “Bahia“',
};

export type VideoCardSearchProps = {
  videoURL: string;
  onSearch: (val: string) => void;
  labels?: {
    title?: string;
    welcome?: string;
    inputPlaceholder?: string;
    searchSample?: string;
  };
};

const VideoCardSearch = (props: VideoCardSearchProps) => {
  const { videoURL, labels = DefaultLabels, onSearch } = props;
  const [search, setSearch] = React.useState('');

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      e?.stopPropagation?.();
      onSearch(search);
    },
    [onSearch, search]
  );

  const handleChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  return (
    <Root>
      <VideoContainer>
        <VideoStyled autoPlay playsInline loop muted src={videoURL} />
      </VideoContainer>
      <DisplayContainer>
        <Container>
          <WelcomeContainer>
            <div style={{ margin: '0% 0% 0% 10%' }}>
              <p style={{ marginTop: '0px', marginBottom: '0.9rem' }}>
                {labels?.welcome}
              </p>
            </div>
          </WelcomeContainer>
          <TitleContainer>
            <Title>{labels?.title}</Title>
          </TitleContainer>
          <SearchContainer>
            <Form onSubmit={handleSubmit}>
              <InputContainer>
                <InputStyled
                  placeholder={labels?.inputPlaceholder}
                  onChange={handleChangeText}
                />
                <SearchButton type="submit">
                  <Search
                    color="inherit"
                    style={{
                      width: 30,
                      height: 30,
                      color: '#aeaeae',
                    }}
                  />
                </SearchButton>
              </InputContainer>
            </Form>
          </SearchContainer>
          <SampleContainer>
            <p
              style={{
                marginTop: '0px',
                boxSizing: 'border-box',
                marginBottom: '0px',
              }}
            >
              {labels?.searchSample}
            </p>
          </SampleContainer>
        </Container>
      </DisplayContainer>
    </Root>
  );
};

export default VideoCardSearch;
